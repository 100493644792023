import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';
import {
  CheckboxWithLabel,
  BaseChip,
  cityToggleConfig,
  CloseButton,
  FormGroupToggle
} from '@spotahome/ui-library';

// lodash utils
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';
import { isFilteredByDate } from '@spotahome/ui-library/src/tenant/components/utils/filters';
import { DSIcon } from '@spotahome/ui-library/base';

import FilterGroup from '../FilterGroup';

import ClearFiltersButton from './ClearFiltersButton';
import CheckboxWithFacet from './CheckboxWithFacet';

import NUMBER_BATHROOMS_TOGGLE from './bathrooms-config';
import BED_TYPE_TOGGLE from './beds-config';
import { FEATURES } from './features-config';
import { RULES } from './rules-config';

import './Filters.scss';

const SHOW_FILTERED_PROPERTIES = 1;
const SHOW_ALL_PROPERTIES = 0;

const isValueChecked = (arr = [], value) => arr.includes(value);

const Filters = ({
  cityId,
  onFiltersUpdate,
  onClearFilters = () => {},
  onCloseFilters = () => {},
  activeFiltersCount = 0
}) => {
  const { filters } = useFiltersContext();

  const handleToggleCheckbox = ({ name, value, isChecked }) => {
    let options = filters[name];
    if (isChecked) {
      options = [...options, value];
    } else {
      options = options.filter(option => option !== value);
    }

    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'filters',
      section: name !== 'bathrooms[]' ? value : `${name}-${value}`,
      action: isChecked ? 'check' : 'uncheck'
    });

    onFiltersUpdate({
      [name]: uniq(options)
    });
  };

  const handleSingleFilterUpdate = (name, eventName) => isChecked => {
    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'filters',
      section: eventName || name,
      action: isChecked ? 'check' : 'uncheck'
    });

    onFiltersUpdate({
      [name]: isChecked ? SHOW_FILTERED_PROPERTIES : SHOW_ALL_PROPERTIES
    });
  };

  const isSingleFilterChecked = name => {
    const filter = parseInt(filters[name], 10);
    return filter === SHOW_FILTERED_PROPERTIES;
  };

  const isFeatureChecked = value =>
    isValueChecked(filters['features[]'], value);

  const handleBathroomClick = (value, isChecked) =>
    handleToggleCheckbox({
      name: 'bathrooms[]',
      value,
      isChecked
    });

  const handleBedTypesClick = (value, isChecked) => {
    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'filters',
      section: `bed-${value}`
    });

    let selectedValues = [];
    if (!isEmpty(filters.bed)) {
      selectedValues = filters.bed.split(',');
    }

    if (isChecked) {
      selectedValues.push(value);
      selectedValues = uniq(selectedValues);
    } else {
      selectedValues = selectedValues.filter(
        currentVal => currentVal !== value
      );
    }

    onFiltersUpdate({ bed: selectedValues.join(',') });
  };

  const handleToggleFilterFeaturesCheckbox = value => isChecked =>
    handleToggleCheckbox({
      name: 'features[]',
      value,
      isChecked
    });

  const handleClearFilters = () => {
    onClearFilters();
  };

  const renderFeaturesGroup = (group, title) => (
    <FilterGroup
      classModifier="checkboxes"
      label={trans(`search.filter.${title}`)}
    >
      {group.map(({ value, labelKey, cy }) => (
        <CheckboxWithFacet
          key={value}
          name={value}
          id={`${value}-id`}
          text={trans(labelKey)}
          callback={handleToggleFilterFeaturesCheckbox(value)}
          checked={isFeatureChecked(value)}
          dataAttributes={{ cy }}
        />
      ))}
    </FilterGroup>
  );

  const showSoldOutFilter = isFilteredByDate(filters);

  return (
    <div data-test="expanded-full-filters">
      <div className="filters__header">
        <div className="filters__title">
          <h1 className="filters__title-text">{trans('filters.title')} </h1>
          <ClearFiltersButton
            activeFiltersCount={activeFiltersCount}
            onClearFilters={handleClearFilters}
          />
        </div>

        <CloseButton onClick={onCloseFilters} />
      </div>

      {showSoldOutFilter && (
        <FilterGroup
          classModifier="checkboxes"
          label={trans('filters.label.availabilities')}
        >
          <CheckboxWithLabel
            id="soldout"
            name="soldout"
            text={trans('search.filter.soldout.text')}
            callback={handleSingleFilterUpdate(
              'includeBlockedProperties',
              'soldout'
            )}
            checked={isSingleFilterChecked('includeBlockedProperties')}
          />
        </FilterGroup>
      )}
      {renderFeaturesGroup(FEATURES, 'features')}
      {renderFeaturesGroup(RULES, 'rules')}
      <FilterGroup
        classModifier="property-type-checkboxes filter-group--smaller"
        label={trans('search.filter.advanced-search')}
      >
        <CheckboxWithFacet
          id="verified"
          name="verified"
          labelTitle={
            <BaseChip
              isSpaced
              inline
              colorSchema="tag"
              label={trans(
                'search.filter.verified.vp.title'
              ).toLocaleUpperCase()}
              renderLeadingIcon={() => (
                <DSIcon
                  name="check_circle_transparent"
                  className="vp-checkbox__icon"
                />
              )}
            />
          }
          text={trans('search.filter.verified.text')}
          callback={handleSingleFilterUpdate('verified')}
          checked={isSingleFilterChecked('verified')}
          alignTop
          className="filter-item--with-chip"
        />

        {cityToggleConfig(cityId).isNoDepositSupported && (
          <CheckboxWithFacet
            id="noDeposit"
            name="noDeposit"
            dataAttributes={{ cy: 'no-deposit-filter' }}
            labelTitle={
              <BaseChip
                isSpaced
                inline
                colorSchema="tag"
                label={trans(
                  'search.filter.no-deposit.vp.title'
                ).toLocaleUpperCase()}
                renderLeadingIcon={() => (
                  <DSIcon name="savings" className="vp-checkbox__icon" />
                )}
              />
            }
            text={trans('search.filter.no-deposit.text')}
            callback={handleSingleFilterUpdate('noDeposit')}
            checked={isSingleFilterChecked('noDeposit')}
            alignTop
            className="filter-item--with-chip"
          />
        )}
      </FilterGroup>
      <FilterGroup
        classModifier="toggle filter-group--smaller"
        label={trans('search.filter.type_bed')}
      >
        <FormGroupToggle
          onChange={handleBedTypesClick}
          selectedValues={filters.bed}
          options={BED_TYPE_TOGGLE}
          key="specific-key-bed"
        />
      </FilterGroup>
      <FilterGroup
        classModifier="align-label-to-first-row filter-group--smaller"
        label={trans('search.filter.bathrooms')}
      >
        <FormGroupToggle
          onChange={handleBathroomClick}
          selectedValues={filters['bathrooms[]'].join(',')}
          options={NUMBER_BATHROOMS_TOGGLE(
            trans('search.filter.bathrooms.2-or-more')
          )}
        />
        <div style={{ position: 'relative', top: '-10px' }}>
          <CheckboxWithFacet
            name="ensuiteRoom"
            id="ensuiteRoom-id"
            dataAttributes={{ cy: 'bathroom-private-bathroom' }}
            text={trans('search.filter.ensuiteRoom')}
            callback={handleToggleFilterFeaturesCheckbox('ensuiteRoom')}
            checked={isFeatureChecked('ensuiteRoom')}
          />
        </div>
      </FilterGroup>
    </div>
  );
};

Filters.propTypes = {
  cityId: PropTypes.string.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func,
  activeFiltersCount: PropTypes.number,
  onCloseFilters: PropTypes.func
};

export default Filters;
