import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import ThemedHomecardWrapper from '../../common/ThemedHomecardWrapper';

import styles from './HomecardWrapper.module.scss';

const cx = classNames.bind(styles);

const HomecardWrapper = ({
  selected = false,
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  renderContent = () => {},
  renderImage = () => {},
  link = '',
  className = '',
  newTab = true,
  horizontal = false,
  hasLazyLoad = true,
  zoomOnHover = false,
  ...restProps
}) => {
  const [hovered, setHovered] = useState(false);

  const setHover = () => {
    if (!selected) {
      setHovered(true);
    }
  };

  const unsetHover = () => {
    if (!selected) {
      setHovered(false);
    }
  };

  const handleMouseEnter = () => {
    setHover();
    onMouseEnter();
  };

  const handleMouseLeave = () => {
    unsetHover();
    onMouseLeave();
  };

  const getWrapperClassName = () =>
    link
      ? cx(styles['home-card'], {
          [styles['home-card--horizontal']]: horizontal,
          [styles['home-card--selected']]: selected,
          [styles['home-card--image-zoom']]: hovered && zoomOnHover
        })
      : cx(styles['home-card']);

  return (
    <ThemedHomecardWrapper
      {...restProps}
      className={className}
      newTab={newTab}
      onClick={onClick}
      hasLazyLoad={hasLazyLoad}
      renderContent={renderContent}
      renderImage={renderImage}
      link={link}
      theme={{
        wrapper: getWrapperClassName(),
        multilistingStack: cx(styles['home-card__multilisting-stack'])
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={setHover}
      onTouchEnd={unsetHover}
    />
  );
};

HomecardWrapper.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  renderContent: PropTypes.func,
  renderImage: PropTypes.func,
  selected: PropTypes.bool,
  horizontal: PropTypes.bool,
  hasLazyLoad: PropTypes.bool,
  zoomOnHover: PropTypes.bool
};

export default HomecardWrapper;
