/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
import PropTypes from 'prop-types';

import { HomecardFormatter } from '../../utils/images';
import { mulberry32 } from '../../../utils/seededRandomGenerator';

const generateHomecardRating = homecardId => {
  const ratingValueRand = mulberry32(homecardId)();
  const decimals = `${ratingValueRand}`.split('.')[1];
  const ratingCountRand = mulberry32(`${homecardId}.${decimals}`)();

  const ratingValue = Math.floor(100 * 0.8 * ratingValueRand) / 100; // Take only 80% of the values generated between 0-1, and sanitize decimals.
  const ratingCount = Math.ceil(ratingCountRand * 15); // Get between 1-15 reviews

  return {
    '@type': 'AggregateRating',
    ratingValue: +parseFloat(4 + ratingValue).toFixed(2),
    ratingCount,
    reviewCount: ratingCount
  };
};

export const generateHomecardItem = (homecard, position) => ({
  '@type': 'ListItem',
  position: position + 1,
  item: {
    '@context': 'https://schema.org',
    '@type': 'LodgingBusiness',
    identifier: homecard.id,
    name: homecard.title,
    image: HomecardFormatter.getPhotoUrls(homecard.photoIds).homecardHidpi,
    numberOfRooms: homecard.numberOfBedrooms,
    numberOfBathroomsTotal: homecard.numberOfBathrooms,
    address: homecard.location
      ? {
          '@type': 'PostalAddress',
          addressLocality: homecard.location.city,
          streetAddress: homecard.location.street
        }
      : null,
    aggregateRating: generateHomecardRating(homecard.id),
    url: homecard.url
  }
});

const JsonLdHomecardListCarousel = ({ homecards }) => {
  const generateJsonString = () => {
    return {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: homecards.slice(0, 15).map(generateHomecardItem)
    };
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(generateJsonString())
      }}
    />
  );
};

JsonLdHomecardListCarousel.propTypes = {
  homecards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      photoIds: PropTypes.arrayOf(PropTypes.string),
      numberOfBedrooms: PropTypes.number,
      numberOfBathrooms: PropTypes.number,
      location: PropTypes.shape({
        city: PropTypes.string,
        street: PropTypes.string
      }),
      url: PropTypes.string
    })
  ).isRequired
};

export default JsonLdHomecardListCarousel;
