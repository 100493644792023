const RoundedMultilistingStack = props => (
  <svg
    width={360}
    height={16}
    viewBox="0 0 360 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={11.755} width={336.49} height={196} rx={8} fill="#E3F0FB" />
    <rect y={8} width={360} height={188} rx={8} fill="#C9E3F7" />
  </svg>
);
export default RoundedMultilistingStack;
