import gql from 'graphql-tag';

const FACET_SEARCH_QUERY = gql`
  query FacetSearch(
    $cityId: String!
    $filters: FiltersInput
    $groupMultilistings: Boolean
  ) {
    facetSearch(
      cityId: $cityId
      filters: $filters
      groupMultilistings: $groupMultilistings
    ) {
      typeRoomShared
      typeApartment
      typeStudio
      typeResidence
      noDeposit
      verified
      wifi
      water
      gas
      electricity
      airConditioning
      washingMachine
      dishwasher
      balcony
      elevator
      oven
      heating
      couples
      students
      professionals
      pets
      withDesk
      ensuiteRoom
    }
  }
`;

export const getFacetSearchQuery =
  client => (cityId, filters, groupMultilistings) =>
    client.query({
      query: FACET_SEARCH_QUERY,
      fetchPolicy: 'cache-first',
      variables: {
        cityId,
        filters,
        groupMultilistings
      }
    });

export default { getFacetSearchQuery };
