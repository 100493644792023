import classNames from 'classnames';

import RoundedMultilistingStack from '../../../SvgImages/RoundedMultilistingStack';

import RectMultilistingStack from '../../../SvgImages/RectMultilistingStack';

import styles from './HomecardMultilistingStack.module.scss';

const HomecardMultilistingStack = () => {
  const roundedClasses = classNames(
    styles['home-card-multilisting-stack'],
    styles['home-card-multilisting-stack--rounded']
  );

  const rectClasses = classNames(
    styles['home-card-multilisting-stack'],
    styles['home-card-multilisting-stack--rect']
  );

  return (
    <>
      <RoundedMultilistingStack className={roundedClasses} />
      <RectMultilistingStack className={rectClasses} />
    </>
  );
};

export default HomecardMultilistingStack;
