import { format, parseISO, differenceInMonths, isValid } from 'date-fns';
import { trans } from '@spotahome/soyuz/client';
import { FilterUtils } from '@spotahome/ui-library';

const filtersParsingConfig = cityCurrency => {
  return {
    moveIn: {
      isValid: moveIn => {
        try {
          return !!moveIn && isValid(new Date(moveIn));
        } catch (error) {
          return false;
        }
      },
      format: moveIn =>
        `${trans('filters.date.from')} ${format(new Date(moveIn), 'dd/M/yyyy')}`
    },
    moveOut: {
      isValid: moveOut => {
        try {
          return !!moveOut && isValid(new Date(moveOut));
        } catch (error) {
          return false;
        }
      },
      format: moveOut =>
        `${trans('filters.date.until')} ${format(
          new Date(moveOut),
          'dd/M/yyyy'
        )}`
    },
    moveOutTo: {
      isValid: moveOutTo => {
        try {
          return !!moveOutTo && isValid(new Date(moveOutTo));
        } catch (error) {
          return false;
        }
      },
      format: (moveOutTo, allFilters) => {
        const moveIn = allFilters.moveIn;
        const moveOutFrom = allFilters.moveOutFrom;
        if (moveIn) {
          const moveOutToMonthDiff = differenceInMonths(
            parseISO(moveOutTo),
            parseISO(moveIn)
          );
          const moveOutFromMonthDiff = differenceInMonths(
            parseISO(moveOutFrom),
            parseISO(moveIn)
          );

          switch (true) {
            case moveOutToMonthDiff <= 6:
              if (moveOutFromMonthDiff <= 1) {
                return `${trans(
                  'search.applied-filters-modal.rentalType.title'
                )}: ${trans(
                  'search.applied-filters-modal.rentalType.shortTerm'
                )}`;
              }
              return `${trans(
                'search.applied-filters-modal.rentalType.title'
              )}: ${trans(
                'search.applied-filters-modal.rentalType.shortTerm-with-minStay'
              )}`;
            case moveOutToMonthDiff > 6 && moveOutToMonthDiff <= 12:
              return `${trans(
                'search.applied-filters-modal.rentalType.title'
              )}: ${trans('search.applied-filters-modal.rentalType.midTerm')}`;
            case moveOutToMonthDiff > 12:
              return `${trans(
                'search.applied-filters-modal.rentalType.title'
              )}: ${trans('search.applied-filters-modal.rentalType.longTerm')}`;
            default:
              return null;
          }
        }
        return null;
      }
    },
    budget: {
      isValid: budget => budget !== '',
      format: budget => FilterUtils.formatBudget(budget, cityCurrency)
    },
    propertyTypes: {
      isValid: propertyTypes => propertyTypes && propertyTypes.length > 0,
      format: propertyTypes =>
        propertyTypes
          .map(propertyType =>
            trans(`search.applied-filters-modal.propertyType.${propertyType}`)
          )
          .join(', ')
    },
    bathrooms: {
      isValid: bathrooms => bathrooms && bathrooms.length > 0,
      format: bathrooms =>
        `${trans('search.applied-filters-modal.bathrooms.title')}: ${bathrooms
          .map(bathroom =>
            trans(`search.applied-filters-modal.bathrooms.${bathroom}`)
          )
          .join(', ')}
          `
    },
    bed: {
      isValid: bed => bed && bed !== '',
      format: bed =>
        `${trans('search.applied-filters-modal.bed.title')}: ${bed
          .split(',')
          .map(splittedBed =>
            trans(`search.applied-filters-modal.bed.${splittedBed}`)
          )
          .join(', ')}`
    },
    features: {
      isValid: features => features && features.length > 0,
      format: features =>
        features
          .map(feature =>
            trans(`search.applied-filters-modal.features.${feature}`)
          )
          .join(', ')
    },
    instantBooking: {
      isValid: instantBooking => instantBooking && instantBooking !== 0,
      format: () => trans('search.applied-filters-modal.instantBooking')
    },
    noDeposit: {
      isValid: noDeposit => noDeposit && noDeposit !== 0,
      format: () => trans('search.applied-filters-modal.noDeposit')
    },
    verified: {
      isValid: verified => verified && verified !== 0,
      format: () => trans('search.applied-filters-modal.verified')
    },
    topology: {
      isValid: topology => topology && topology.length > 0,
      format: topology =>
        `${trans('search.applied-filters-modal.topology.title')}: ${topology
          .map(poi => trans(`search.applied-filters-modal.topology.${poi}`))
          .join(', ')}`
    },
    rentalType: {
      isValid: rentalType => rentalType && rentalType.length > 0,
      format: rentalType =>
        `${trans('search.applied-filters-modal.rentalType.title')}: ${rentalType
          .map(type => trans(`search.applied-filters-modal.rentalType.${type}`))
          .join(', ')}`
    }
  };
};

export const getAlertContent = (alert, currentCityCurrency) => {
  const filterConfig = filtersParsingConfig(currentCityCurrency);
  const result = Object.entries(alert)
    .filter(([key, value]) => {
      return filterConfig[key] && filterConfig[key].isValid(value);
    })
    .map(([key, value]) => {
      return filterConfig[key].format(value, alert);
    });
  return result;
};
