import PropTypes from 'prop-types';
import { getMonth, getYear } from 'date-fns';

import { trans } from '@spotahome/soyuz/client';

import classNames from 'classnames/bind';

import Price from '../../common/Price';
import Button from '../../../Button';

import Tag from '../../../Tag';
import BaseChip from '../../../BaseChip';

import {
  ROOM_SHARED,
  ROOM_SHARED_APT,
  LISTING_TYPES
} from '../../../../data/common/property';

import { formatDateDay } from '../../../utils/dateUtils';

import styles from './HomecardContent.module.scss';

const DEFAULT_LISTING_TYPE = ROOM_SHARED;
const TRANS_DELIMITER = '_';

const TRANSLATION_TYPE_MAPPING = {
  [ROOM_SHARED_APT]: ROOM_SHARED
};

const getTranslatedMonth = month => {
  const translatedMonths = trans('pikaday.label.months').split(TRANS_DELIMITER);

  return translatedMonths[month];
};

const getTranslatedType = type => {
  const mappedType = TRANSLATION_TYPE_MAPPING[type] || type;
  return trans(`homecard.type.${mappedType}.small`);
};

const parseAvailableFrom = availableFrom => {
  const date = new Date(availableFrom);
  const month = getMonth(date);
  const translatedMonth = getTranslatedMonth(month);

  const year = getYear(date);

  return {
    day: formatDateDay(date),
    month: translatedMonth,
    year
  };
};

const HomecardContentDescription = ({
  position = 'default',
  onMoreInfoClick
}) => (
  <div className={styles[`homecard-content__info--${position}`]}>
    <Button color="link" onClick={onMoreInfoClick}>
      {trans('homecard.more.info')}
    </Button>
  </div>
);

const HomecardContent = ({
  availableFrom,
  title,
  link,
  target,
  onClick,
  price = {},
  type = DEFAULT_LISTING_TYPE,
  chips = [],
  showAvailableFromYear = false,
  withTransparentBackground = false,
  onMoreInfoClick = () => {},
  hasDescription = false,
  descriptionPosition = 'default',
  offerDiscount = null,
  isBooked = false,
  isEmbedded = false
}) => {
  const { day, month, year } = parseAvailableFrom(availableFrom);
  const hasChips = chips.length;

  const showDefaultDescription =
    hasDescription && descriptionPosition === 'default' && !isBooked;

  const showDescriptionAtBottom =
    hasDescription && descriptionPosition === 'bottom' && !isBooked;

  const Component = isBooked ? 'span' : 'a';
  const componentProps = isBooked
    ? {}
    : {
        href: link,
        target,
        onClick,
        'data-test': 'homecard-link'
      };

  return (
    <div
      className={classNames(styles['homecard-content'], {
        [styles['homecard-content--with-transparent-background']]:
          withTransparentBackground
      })}
      data-test="homecard-content"
    >
      <Component
        className={styles['homecard-content__header']}
        {...componentProps}
      >
        {isBooked ? (
          <span className={styles['homecard-content__booked']}>
            {trans('homecard.is-booked.description')}
          </span>
        ) : (
          <div>
            <span
              className={classNames(styles['homecard-content__type'], {
                [styles['homecard-content__type--variant']]: isEmbedded
              })}
            >
              {getTranslatedType(type)}
            </span>

            <span className={styles['homecard-content__separator']}>
              &#9632;
            </span>

            <span
              className={classNames(
                styles['homecard-content__available-from'],
                {
                  [styles['homecard-content__available-from--variant']]:
                    isEmbedded
                }
              )}
              dangerouslySetInnerHTML={{
                __html: trans('homecard.available-from.rebranding', {
                  ordinalDate: showAvailableFromYear
                    ? `${day} ${month} ${year}`
                    : `${day} ${month}`
                })
              }}
            />
          </div>
        )}

        <p
          className={classNames(styles['homecard-content__title'], {
            [styles['homecard-content__title--variant']]: isEmbedded
          })}
        >
          {title}
        </p>
      </Component>

      <div className={styles['homecard-content__footer']}>
        <div className={styles['homecard-content__price-container']}>
          <Price
            {...price}
            color="dark"
            className={styles['homecard-content__price']}
            biggerFont={isEmbedded}
            biggerFontMonthly={isEmbedded}
          />
          {offerDiscount && (
            <Tag
              className={styles['homecard-content__price-tag']}
              colorSchema="pink"
              label={trans('homecard.label.up_to_discount', {
                discount: offerDiscount
              }).toUpperCase()}
            />
          )}
        </div>
        {showDefaultDescription && (
          <HomecardContentDescription onMoreInfoClick={onMoreInfoClick} />
        )}
        {hasChips ? (
          <div className={styles['homecard-content__label-container']}>
            {chips.map(chip => (
              <Tag
                colorSchema={chip.colorSchema || 'tag'}
                key={chip.label}
                {...chip}
              />
            ))}
          </div>
        ) : null}
        {showDescriptionAtBottom && (
          <HomecardContentDescription
            position="bottom"
            onMoreInfoClick={onMoreInfoClick}
          />
        )}
      </div>
    </div>
  );
};

HomecardContent.propTypes = {
  availableFrom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  price: PropTypes.shape(Price.propTypes),
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(LISTING_TYPES),
  chips: PropTypes.arrayOf(PropTypes.shape(BaseChip.propTypes)),
  showAvailableFromYear: PropTypes.bool,
  withTransparentBackground: PropTypes.bool,
  onMoreInfoClick: PropTypes.func,
  hasDescription: PropTypes.bool,
  descriptionPosition: PropTypes.oneOf(['bottom', 'default']),
  offerDiscount: PropTypes.string,
  link: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isBooked: PropTypes.bool,
  area: PropTypes.number,
  roomArea: PropTypes.number,
  showSquaredMeters: PropTypes.bool,
  isEmbedded: PropTypes.bool
};

export default HomecardContent;
