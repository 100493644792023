import { useEffect } from 'react';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

const calculateLoadTime = () => {
  if (!window.performance || !window.performance.now) {
    return null;
  }

  const pageLoadTime = performance.now();
  return Math.round((pageLoadTime / 1000 + Number.EPSILON) * 100) / 100;
};

const usePageLoadTime = () => {
  const onPageLoad = () => {
    const pageLoadTime = calculateLoadTime();
    if (pageLoadTime) {
      SoyuzAnalytics.sendGA4Event('page-load-time', { loading_time_sec: pageLoadTime });
    }
  };

  useEffect(() => {
    window.addEventListener('load', onPageLoad);

    return () => {
      window.removeEventListener('load', onPageLoad);
    };
  }, []);
};

export default usePageLoadTime;
