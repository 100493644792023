import { lazy, Suspense } from 'react';

import NoSSR from 'react-no-ssr';

import Spinner from '../Spinner/Spinner';

const ModalOptIn = lazy(() => import('./ModalOptIn'));

const LazyModalOptIn = props => (
  <NoSSR>
    <Suspense fallback={<Spinner small />}>
      <ModalOptIn {...props} />
    </Suspense>
  </NoSSR>
);

export default LazyModalOptIn;
