/* eslint-disable no-underscore-dangle */
import NoSSR from 'react-no-ssr';
import { clientErrorTracker, trans } from '@spotahome/soyuz/client';

import LazyModalOptIn from '@spotahome/ui-library/src/tenant/components/ModalOptIn';

import getGqlClient from '../../graphql/client';
import setMarketingOptIn from '../../graphql/mutations/marketing-opt-in';

const handleOptInAccepted = async () => {
  try {
    await setMarketingOptIn(getGqlClient())();
  } catch (error) {
    clientErrorTracker.error({ error, source: 'OptInModalWrapper-Accepted' });
  }
};

const OptInModalWrapper = () => (
  <NoSSR>
    <LazyModalOptIn
      modalBodytext={trans('opt-in-checkbox-question')}
      checkboxText={trans('opt-in-checkbox-text')}
      buttonCtaText={trans('opt-in-button-cta')}
      setMarketingOptIn={handleOptInAccepted}
    />
  </NoSSR>
);

export default OptInModalWrapper;
