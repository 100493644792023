import {
  useSoyuzLocales,
  useSoyuzClientConfig,
  useSoyuzExperiment
} from '@spotahome/soyuz/client';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import { useTenantTypeContext } from '@spotahome/ui-library';

import { useHighlightMarkersContext } from '@spotahome/marketplace-common/src/context/HighlightMarkersContext';

import Search from './Search';

const SearchContainer = props => {
  const clientConfig = useSoyuzClientConfig();
  const { current } = useSoyuzLocales();
  const { filters, handleUpdateFilters, handleUpdatePage, handleUpdateMap } =
    useFiltersContext();
  const { tenantType } = useTenantTypeContext();
  const isSocialProofSearchMapExperiment =
    useSoyuzExperiment('SocialProofSearchMap').get('behaviour') === 'variant';
  const { markerClickedId } = useHighlightMarkersContext();
  const isDesktopMapHomecardExp =
    useSoyuzExperiment('DesktopMapHomecard').get('behaviour') === 'variant';

  const isMultilistingsPerceptionSearchExp =
    useSoyuzExperiment('MultilistingsPerceptionSearch').get('behaviour') ===
    'variant';

  return (
    <Search
      {...props}
      clientConfig={clientConfig}
      currentLocale={current}
      filters={filters}
      handleUpdateFilters={handleUpdateFilters}
      handleUpdatePage={handleUpdatePage}
      handleUpdateMap={handleUpdateMap}
      tenantType={tenantType}
      numberOfTenants={
        isSocialProofSearchMapExperiment ? props.numberOfTenants : null
      }
      selectedMarkerId={isDesktopMapHomecardExp ? markerClickedId : null}
      isMultilistingsPerceptionSearchExp={isMultilistingsPerceptionSearchExp}
    />
  );
};

export default SearchContainer;
