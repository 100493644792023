import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CloseButton from '../CloseButton';

import styles from './InfoBanner.module.scss';

const InfoBanner = memo(
  ({
    theme,
    content,
    dataTest,
    className,
    contentClassName,
    onCloseBanner,
    closable,
    closePosition,
    icon,
    iconPosition
  }) => {
    const [isOpen, setIsOpen] = useState(true);

    if (!isOpen) return null;

    const infoBannerClasses = classNames(
      styles['info-banner'],
      styles[`info-banner--${theme}`],
      className
    );
    const closeButtonClasses = classNames(
      styles['info-banner__close'],
      styles[`info-banner__close--${closePosition}`]
    );

    const iconClasses = classNames(
      styles['info-banner__icon'],
      styles[`info-banner__icon--${iconPosition}`]
    );

    const contentClasses = classNames(
      styles['info-banner__text'],
      contentClassName
    );

    const handleClose = () => {
      setIsOpen(false);
      onCloseBanner();
    };

    return (
      <div className={infoBannerClasses} data-test={dataTest}>
        {icon ? <div className={iconClasses}>{icon}</div> : null}
        <div className={contentClasses}>{content}</div>
        {closable ? (
          <CloseButton
            size="small"
            className={closeButtonClasses}
            onClick={handleClose}
          />
        ) : null}
      </div>
    );
  }
);

InfoBanner.propTypes = {
  theme: PropTypes.oneOf([
    'info',
    'suggestion',
    'suggestion-light',
    'tertiary'
  ]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  dataTest: PropTypes.string,
  className: PropTypes.string,
  onCloseBanner: PropTypes.func,
  closable: PropTypes.bool,
  closePosition: PropTypes.oneOf(['top', 'center']),
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['top', 'center']),
  contentClassName: PropTypes.string
};

InfoBanner.defaultProps = {
  theme: 'info',
  dataTest: 'info-banner',
  className: '',
  closable: true,
  onCloseBanner: () => {},
  closePosition: 'top',
  icon: null,
  iconPosition: 'top',
  contentClassName: ''
};

export default InfoBanner;
