const RectMultilistingStack = props => (
  <svg
    width={726}
    height={19}
    viewBox="0 0 726 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={678.588}
      height={234.299}
      transform="translate(23.7062)"
      fill="#E3F0FB"
    />
    <rect
      width={726}
      height={224.736}
      transform="translate(0 9.56323)"
      fill="#C9E3F7"
    />
  </svg>
);
export default RectMultilistingStack;
