import PropTypes from 'prop-types';

import { trans, useSoyuzClientConfig } from '@spotahome/soyuz/client';
import { Button } from '@spotahome/ui-library';

import { getStaticMapUrl } from '../../utils/static-map';

import './MapPlaceholder.scss';
import TenantBanner from '../TenantsBanner';

const MapPlaceholder = ({
  city,
  cityCenterCoords,
  onButtonClick,
  numberOfTenants = null
}) => {
  const { defaultZoom, googleMapsApiKey } = useSoyuzClientConfig();
  const cityZoom = defaultZoom[city];

  return (
    <div className="map-placeholder">
      <div className="map-placeholder__tenants-banner">
        {numberOfTenants ? (
          <TenantBanner numberOfTenants={numberOfTenants} />
        ) : null}
      </div>
      <img
        className="map-placeholder__image map-placeholder__image--blur"
        src={getStaticMapUrl({
          lat: cityCenterCoords.lat,
          lng: cityCenterCoords.lng,
          zoom: cityZoom,
          apiKey: googleMapsApiKey
        })}
        alt="map placeholder"
      />

      <Button
        onClick={onButtonClick}
        color="secondary"
        data-test="search-map-activation"
      >
        {trans('map.lazy.cta.text')}
      </Button>
    </div>
  );
};

MapPlaceholder.propTypes = {
  city: PropTypes.string.isRequired,
  cityCenterCoords: PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string
  }).isRequired,
  onButtonClick: PropTypes.func.isRequired,
  numberOfTenants: PropTypes.number
};

export default MapPlaceholder;
