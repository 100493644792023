import PropTypes from 'prop-types';

import IconGeneric from '../IconGeneric';

import './FormGroupToggle.scss';

const FormSimpleToggle = ({
  id,
  value,
  label = null,
  checked = false,
  cy = null,
  onChange,
  icon = '',
  color = '',
  tracking = ''
}) => {
  const handleOnChange = () => {
    onChange(value, !checked);
  };

  return (
    <div className="form-simple-toggle">
      <input
        className={`form-simple-toggle__input ${tracking}`}
        type="checkbox"
        id={id}
        data-cy={cy}
        value={value}
        onChange={handleOnChange}
        checked={checked}
      />
      <label className="form-simple-toggle__label" htmlFor={id}>
        {icon ? <IconGeneric name={icon} color={color} size="large" /> : null}
        {label}
      </label>
    </div>
  );
};

FormSimpleToggle.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  cy: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  tracking: PropTypes.string
};

const SELECTED_VALUES_DELIMETER = ',';

const FormGroupToggle = ({ options, selectedValues = '', onChange }) => {
  const isChecked = value => {
    if (undefined === selectedValues) {
      return false;
    }

    return selectedValues.split(SELECTED_VALUES_DELIMETER).includes(value);
  };

  const handleChange = (value, checked) => {
    onChange(value, checked);
  };

  return (
    <div className="form-group-toggle">
      {options.map(option => (
        <FormSimpleToggle
          key={option.id}
          id={option.id}
          cy={option.cy}
          value={option.value}
          label={option.label}
          icon={option.icon}
          checked={isChecked(option.value)}
          onChange={handleChange}
          tracking={option.tracking}
        />
      ))}
    </div>
  );
};

FormGroupToggle.propTypes = {
  selectedValues: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cy: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      tracking: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

export default FormGroupToggle;
