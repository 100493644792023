import { memo, useState } from 'react';
import PropTypes from 'prop-types';

import HomecardMultilistingStack from '../DefaultHomecard/components/HomecardMultilistingStack';

import { navigateTo } from '../../../../utils/navigateTo';
import { propTypes } from '../../ThemedPrice';

const inlineStylesHidden = { display: 'none' };
const inlineStylesShown = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  height: '100%',
  width: '100%',
  zIndex: 3
};

const ThemedHomecardWrapper = memo(
  ({
    newTab,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onTouchEnd,
    onTouchStart,
    selected,
    theme,
    link,
    dataTest,
    renderDescription,
    onMoreInfoClick,
    description,
    renderImage,
    isBooked,
    isMultilisting,
    ...restProps
  }) => {
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
    const homecardTarget = newTab ? '_blank' : '_self';
    const hasDescription = description !== null;

    const handleClick = async e => {
      e.preventDefault();
      await onClick();
      if (!isBooked) {
        navigateTo(link, { newTab });
      }
    };

    const onMoreInfoClickHandler = e => {
      e.stopPropagation();
      e.preventDefault();
      onMoreInfoClick();
      setIsDescriptionVisible(true);
    };

    const onCloseClick = e => {
      e.stopPropagation();
      e.preventDefault();
      setIsDescriptionVisible(false);
    };

    const renderHomecardContent = ({ renderContent, renderExtraContent }) => (
      <>
        {renderContent({
          ...restProps,
          isBooked,
          hasDescription,
          onMoreInfoClick: onMoreInfoClickHandler,
          link,
          target: homecardTarget,
          onClick: handleClick
        })}
        {renderExtraContent(restProps)}
      </>
    );

    const Component = isBooked ? 'span' : 'a';
    const componentProps = isBooked
      ? {}
      : {
          href: link,
          target: homecardTarget,
          onClick,
          onMouseEnter,
          onMouseLeave,
          onTouchEnd,
          onTouchStart
        };

    return (
      <>
        {isMultilisting && (
          <div className={theme.multilistingStack}>
            <HomecardMultilistingStack />
          </div>
        )}
        <Component
          data-test={dataTest}
          className={theme.wrapper}
          {...componentProps}
        >
          {renderImage({ ...restProps, isBooked, onClick: handleClick })}
        </Component>
        {renderHomecardContent({
          ...restProps,
          link
        })}

        {hasDescription && (
          <div
            style={
              !isDescriptionVisible ? inlineStylesHidden : inlineStylesShown
            }
          >
            {renderDescription({
              ...restProps,
              isBooked,
              description,
              link,
              linkTarget: homecardTarget,
              onCloseClick
            })}
          </div>
        )}
      </>
    );
  }
);

ThemedHomecardWrapper.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  renderContent: PropTypes.func,
  renderImage: PropTypes.func,
  selected: PropTypes.bool,
  theme: PropTypes.shape({
    wrapper: PropTypes.string,
    multilistingStack: PropTypes.string
  }),
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string
    })
  ),
  renderExtraContent: PropTypes.func,
  renderDescription: PropTypes.func,
  dataTest: propTypes.string,
  onMoreInfoClick: PropTypes.func,
  description: PropTypes.shape({}),
  isBooked: PropTypes.bool,
  isMultilisting: PropTypes.bool,
  otherMultilistingsPresent: PropTypes.bool
};

ThemedHomecardWrapper.defaultProps = {
  className: '',
  newTab: true,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onTouchEnd: () => {},
  onTouchStart: () => {},
  renderContent: () => {},
  renderImage: () => {},
  selected: false,
  theme: {},
  photos: [],
  renderExtraContent: () => {},
  renderDescription: () => {},
  dataTest: '',
  onMoreInfoClick: () => {},
  description: null,
  isBooked: false,
  isMultilisting: false,
  otherMultilistingsPresent: false
};

export default ThemedHomecardWrapper;
