export const RULES = [
  {
    value: 'couples',
    labelKey: 'search.filter.couples',
    tracking: 'ga-search-form-couples',
    cy: 'rules-couples'
  },
  {
    value: 'pets',
    labelKey: 'search.filter.pets',
    tracking: 'ga-search-form-pets',
    cy: 'rules-pets'
  },
  {
    value: 'students',
    labelKey: 'search.filter.students',
    tracking: 'ga-search-form-students',
    cy: 'rules-students'
  },
  {
    value: 'professionals',
    labelKey: 'search.filter.professionals',
    tracking: 'ga-search-form-professionals',
    cy: 'rules-professionals'
  }
];
