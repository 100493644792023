import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HOMECARD_IMAGE_RATIOS, CloseButton } from '@spotahome/ui-library';
import { useSoyuzLocales } from '@spotahome/soyuz/client';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';
import { Mediator } from '@spotahome/soyuz-mediator';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';
import { HomecardFormatter } from '@spotahome/ui-library/src/tenant/utils/images';

import { useHighlightMarkersContext } from '../../context/HighlightMarkersContext';

import Homecard from '../Homecard';

import getGqlClient from '../../graphql/client';
import getHomecardsQuery from '../../graphql/queries/homecards';
import { getMonthlyPrice } from '../../utils/homecards';
import { HOMECARD_MAP_CLOSE, HOMECARD_MAP_OPEN } from '../../channels';
import useHomecardListTracking from '../../utils/homecards-tracking';

import HomeCardPlaceHolder from '../Homecard/HomeCardPlaceHolder';

import './HomecardMap.scss';

const fetchHomecard = async (id, locale) => {
  const { data } = await getHomecardsQuery(getGqlClient())([id], locale, false);
  return (data && data.homecards && data.homecards[0]) || undefined;
};

const handleHomecardImageChange = (action, { index }) => {
  SoyuzAnalytics.sendGA4Event(`homecard-image-slide`, { action, index });
};

const HomecardMap = ({ cityId, deviceType }) => {
  const [loading, setLoading] = useState(false);
  const [homecard, setHomecard] = useState();
  const { filters } = useFiltersContext();
  const { current: locale } = useSoyuzLocales();
  const moveIn = filters['move-in'];
  const moveOut = filters['move-out'];
  const { trackHomecardClick } = useHomecardListTracking(cityId);
  const { removeSelectedMarker, markerClickedId: homecardId } =
    useHighlightMarkersContext();

  const currentYear = new Date().getFullYear();

  const firstAvailableDate = homecard && new Date(homecard.firstAvailableDate);
  const firstAvailableYear = firstAvailableDate?.getFullYear();
  const shouldShowAvailableFrom = !filters['move-in'];

  const triggerHomecardOpen = () => {
    Mediator.publish(HOMECARD_MAP_OPEN);
  };

  useEffect(() => {
    if (homecardId) {
      setLoading(true);
      triggerHomecardOpen();
      fetchHomecard(homecardId, locale)
        .then(result => {
          setHomecard({
            ...result,
            monthlyPrice: getMonthlyPrice(result.displayPrice)
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          Mediator.publish(HOMECARD_MAP_CLOSE);
        });
    } else {
      Mediator.publish(HOMECARD_MAP_CLOSE);
    }
  }, [homecardId, locale]);

  useEffect(() => {
    if (homecard) {
      triggerHomecardOpen();
    }
    return () => {
      // hide component from outside
      Mediator.publish(HOMECARD_MAP_CLOSE);
    };
  }, []);

  if (!homecardId) {
    return null;
  }

  if (loading || !homecard) {
    return (
      <div className="homecard-map">
        <HomeCardPlaceHolder />
      </div>
    );
  }

  const handleCloseMarker = () => {
    removeSelectedMarker();
    Mediator.publish(HOMECARD_MAP_CLOSE);
  };

  const homecardData = {
    ...homecard,
    photos: HomecardFormatter.getPhotos(
      homecard.photoIds,
      homecard.otherPropertyPhotoIds
    ),
    monthlyPrice: getMonthlyPrice(homecard.displayPrice)
  };

  return (
    <div className="homecard-map">
      <CloseButton
        className="homecard-map__close"
        size="small"
        onClick={handleCloseMarker}
      />
      <Homecard
        isShowStatus
        data={homecardData}
        moveIn={moveIn}
        moveOut={moveOut}
        flexibleMoveOut={!!filters.flexibleMoveOut}
        flexibleMoveIn={!!filters.flexibleMoveIn}
        onClick={() => {
          trackHomecardClick(0, homecardData);
        }}
        reviews={homecardData.reviews}
        hasLazyLoad={false}
        showPrice
        newTab={!(deviceType === 'smartphone' || deviceType === 'tablet')}
        showAvailableFromYear={
          shouldShowAvailableFrom && firstAvailableYear !== currentYear
        }
        pageType="search"
        onImageChange={handleHomecardImageChange}
        isBooked={homecard.so}
        showSquaredMeters
        imageRatio={HOMECARD_IMAGE_RATIOS.PANORAMIC}
        isEmbedded
        dataTest="horizontal-homecard"
      />
    </div>
  );
};

HomecardMap.propTypes = {
  cityId: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired
};

export default HomecardMap;
