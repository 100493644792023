import { useState, useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { MediaQuery } from '@spotahome/ui-library';
import { useSoyuzExperiment } from '@spotahome/soyuz/client';

const HighlightMarkersContext = createContext({
  handleCardMouseEnter: () => {},
  handleCardMouseLeave: () => {},
  handleMarkerClicked: () => {},
  removeSelectedMarker: () => {},
  homecardMouseEnterId: null,
  markerClickedId: null
});

function scrollToCard(markerId) {
  if (markerId) {
    const homecard = document.querySelector(
      `[data-homecard-scroll="${markerId}"]`
    );

    if (homecard) {
      homecard.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start'
      });
    }
  }
}

export const HighlightMarkersProvider = ({ children }) => {
  const [homecardMouseEnterId, setHomecardMouseEnterId] = useState(null);
  const [markerClickedId, setMarkerClickedId] = useState(null);

  const handleCardMouseEnter = adId => {
    setHomecardMouseEnterId(parseInt(adId, 10));
    setMarkerClickedId(null);
  };

  const handleCardMouseLeave = () => {
    setHomecardMouseEnterId(null);
  };

  const DesktopMapHomecardExp = useSoyuzExperiment('DesktopMapHomecard');

  const getDesktopMapHomecardExp = () =>
    DesktopMapHomecardExp.get('behaviour') === 'variant';

  const handleMarkerClicked = newMarkerId => {
    setMarkerClickedId(prevMarker => {
      if (prevMarker && newMarkerId === prevMarker) {
        return null;
      }
      return parseInt(newMarkerId, 10);
    });
  };

  const removeSelectedMarker = () => {
    setMarkerClickedId(null);
  };

  useEffect(() => {
    if (
      markerClickedId &&
      MediaQuery.isBiggerThanTablet() &&
      !getDesktopMapHomecardExp()
    ) {
      // This should be done inside search component.
      scrollToCard(markerClickedId);
    }
  }, [markerClickedId]);

  return (
    <HighlightMarkersContext.Provider
      value={{
        handleCardMouseEnter,
        handleCardMouseLeave,
        handleMarkerClicked,
        removeSelectedMarker,
        homecardMouseEnterId,
        markerClickedId
      }}
    >
      {children}
    </HighlightMarkersContext.Provider>
  );
};

HighlightMarkersProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export const useHighlightMarkersContext = () =>
  useContext(HighlightMarkersContext);

export const HighlightMarkersConsumer = HighlightMarkersContext.Consumer;

export const useHomecardMouseEnterId = () => {
  const { homecardMouseEnterId } = useHighlightMarkersContext();

  return homecardMouseEnterId;
};

export const useMarkerClickedId = () => {
  const { markerClickedId } = useHighlightMarkersContext();

  return markerClickedId;
};

export default {
  HighlightMarkersProvider,
  HighlightMarkersConsumer,
  useHighlightMarkersContext,
  useHomecardMouseEnterId,
  useMarkerClickedId
};
