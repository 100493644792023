import PropTypes from 'prop-types';
import { DSIcon } from '@spotahome/ui-library/base';
import { trans } from '@spotahome/soyuz/client';

import './TenantsBanner.scss';

const TenantBanner = ({ numberOfTenants }) => {
  return (
    <p className="tenants-banner">
      <DSIcon name="perfect_for_couples" />
      <span>{numberOfTenants}</span> {trans('tenants-banner.text')}
    </p>
  );
};

TenantBanner.propTypes = {
  numberOfTenants: PropTypes.number.isRequired
};

export default TenantBanner;
