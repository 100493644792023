import gql from 'graphql-tag';

const QUERY_MARKERS = gql`
  query Markers($cityId: String!, $filters: FiltersInput) {
    search(cityId: $cityId, filters: $filters) {
      markers {
        id
        coord
        instantBooking
        minimumPrice
        so
        totalCountMultilisting
      }
    }
  }
`;

export const getMarkersQuery = client => (cityId, filters) =>
  client.query({
    query: QUERY_MARKERS,
    variables: {
      cityId,
      filters
    }
  });

export default getMarkersQuery;
