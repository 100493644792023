import {
  addMonths,
  subDays,
  addDays,
  setDate,
  isBefore,
  isAfter,
  isEqual,
  differenceInYears,
  differenceInHours
} from 'date-fns';

import {
  getDateAsUTC,
  formatDate,
  formatReadableDate,
  MONTH_ABBRV_DATE_FORMAT
} from './dateUtils';

const FLEXIBLE_DAYS = 15;

export const getMonths = (from, to, dateFormat = 'MM') => {
  const startDate = getDateAsUTC(
    typeof from === 'string' ? new Date(from) : new Date()
  );
  const monthsArr = [formatDate(startDate, dateFormat)];
  let endDate = getDateAsUTC(
    typeof to === 'string' ? new Date(to) : new Date()
  );
  // So the date is always lower than the initial date (we are only using months),
  // This way, we can compare months directly
  let currentDate = setDate(startDate, 2);
  endDate = setDate(endDate, 1);

  while (isBefore(currentDate, endDate)) {
    const date = addMonths(currentDate, 1);
    monthsArr.push(formatDate(date, dateFormat));
    currentDate = date;
  }

  return monthsArr;
};

export const getMonthsStaying = (
  { moveIn, moveOut },
  { flexibleMoveIn = false, flexibleMoveOut = false } = {}
) => {
  if (!moveIn || !moveOut) {
    return undefined;
  }

  const moveInUtc = getDateAsUTC(moveIn);
  const moveOutUtc = getDateAsUTC(moveOut);

  const flexibleRangeMoveIn = flexibleMoveIn
    ? formatDate(subDays(moveInUtc, FLEXIBLE_DAYS))
    : moveIn;

  const flexibleRangeMoveOut = flexibleMoveOut
    ? formatDate(addDays(moveOutUtc, FLEXIBLE_DAYS))
    : moveOut;

  return getMonths(
    flexibleRangeMoveIn,
    flexibleRangeMoveOut,
    MONTH_ABBRV_DATE_FORMAT
  ).map(month => month.toLowerCase());
};

export const getUserAge = (birthDate, today = new Date()) =>
  differenceInYears(today, getDateAsUTC(birthDate));

export const getDaysDiff = (start, end) => {
  return (
    Math.trunc(differenceInHours(new Date(end), new Date(start)) / 24) || 0 // to avoid issues with hour change
  );
};

export const dateIsBetween = (date, initialRangeDate, endRangeDate) =>
  (isAfter(date, initialRangeDate) && isBefore(date, endRangeDate)) ||
  isEqual(date, initialRangeDate) ||
  isEqual(date, endRangeDate);

export const checkIfAvailable = (moveIn, moveOut, unavailabilities) => {
  const startDate = getDateAsUTC(moveIn);
  const endDate = getDateAsUTC(moveOut);

  return !unavailabilities.some(u => {
    const fromU = getDateAsUTC(u.from);
    const toU = getDateAsUTC(u.to);

    return (
      dateIsBetween(startDate, u.from, u.to) ||
      dateIsBetween(endDate, u.from, u.to) ||
      dateIsBetween(fromU, startDate, endDate) ||
      dateIsBetween(toU, startDate, endDate)
    );
  });
};

export const formatReadableDateRange = (moveIn, moveOut) => {
  if (!moveIn && !moveOut) {
    return null;
  }

  const dates = [];
  if (moveIn) {
    dates.push(formatReadableDate(moveIn));
  }

  if (moveOut) {
    dates.push(formatReadableDate(moveOut));
  }

  return dates.join(' - ');
};

export const dateIsEqualOrAfter = (date, dateToCompare) =>
  isAfter(date, dateToCompare) || isEqual(date, dateToCompare);

export const dateIsEqualOrBefore = (date, dateToCompare) =>
  isBefore(date, dateToCompare) || isEqual(date, dateToCompare);
