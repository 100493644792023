import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AdUrlService from '@spotahome/marketplace-common/src/utils/ad-url-service';

import { useSoyuzExperiment } from '@spotahome/soyuz/client';

import getGqlClient from '../clients/graphql/marketplace';

import { FilterUtils } from '../../..';
import { getFacetSearchQuery } from '../queries/graphql/facetSearch';
import { isFilteredByDate } from '../components/utils/filters';

const FiltersContext = createContext({
  handleUpdateFilters: () => {},
  requestFacetSearch: () => {},
  handleUpdatePage: () => {},
  handleUpdateMap: () => {},
  filters: {},
  facetSearch: {}
});

const parseFlexibleMoveFilters = ({
  flexibleMoveIn,
  flexibleMoveOut,
  ...other
}) => ({
  ...other,
  flexibleMoveIn: flexibleMoveIn === 0 ? '0' : false,
  flexibleMoveOut: flexibleMoveOut === 0 ? '0' : false
});

export const FiltersProvider = ({
  initialFilters = null,
  locationString,
  cityId,
  shouldUpdateUrl = true,
  children
}) => {
  const [filters, setFilters] = useState(initialFilters);
  const [facetSearch, setFacetSearch] = useState({});
  const [page, setPage] = useState(initialFilters?.page || 1);
  const [map, setMap] = useState(!!initialFilters?.map);
  const isMultilistingsPerceptionSearch =
    useSoyuzExperiment('MultilistingsPerceptionSearch').get('behaviour') ===
    'variant';

  useEffect(() => {
    if (!cityId) {
      return;
    }
    const requestFacetSearch = async () => {
      try {
        const facetSearchResponse = await getFacetSearchQuery(getGqlClient())(
          cityId,
          FilterUtils.adaptFiltersFromUrl(filters),
          !isMultilistingsPerceptionSearch
        );

        setFacetSearch(facetSearchResponse.data.facetSearch);
      } catch (error) {
        console.error(error);
      }
    };

    requestFacetSearch();
  }, [filters, cityId]);

  useEffect(() => {
    if (shouldUpdateUrl) {
      AdUrlService.setSearchLocationUrl(
        locationString,
        parseFlexibleMoveFilters({
          ...filters,
          page,
          map
        })
      );
    }
  }, [filters, page, map]);

  const handleUpdateFilters = newFilters => {
    const datesWereSelected = isFilteredByDate(filters);
    const datesAreSelected = isFilteredByDate(newFilters);
    const noDatesPreviouslySelected = !datesWereSelected && datesAreSelected;

    const newFiltersWithSoldOut = {
      ...newFilters,
      ...(noDatesPreviouslySelected && !newFilters.includeBlockedProperties
        ? { includeBlockedProperties: 1 }
        : {})
    };

    setFilters(newFiltersWithSoldOut);
  };

  // This function could be in a different context -- ViewSearchContext
  const handleUpdatePage = newPage => {
    setPage(newPage);
  };

  // This function could be in a different context -- ViewSearchContext
  const handleUpdateMap = mapState => {
    setMap(mapState);
  };

  return (
    <FiltersContext.Provider
      value={{
        filters: {
          ...filters,
          page,
          map
        },
        handleUpdateFilters,
        handleUpdatePage,
        handleUpdateMap,
        facetSearch
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  initialFilters: PropTypes.shape({
    page: PropTypes.number,
    map: PropTypes.string // String because it comes from the url as "true"
  }),
  cityId: PropTypes.string.isRequired,
  locationString: PropTypes.string.isRequired,
  shouldUpdateUrl: PropTypes.bool
};

export const useFiltersContext = () => useContext(FiltersContext);
export const FiltersConsumer = FiltersContext.Consumer;

export default {
  FiltersProvider,
  FiltersConsumer,
  useFiltersContext
};
